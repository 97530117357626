// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

p {
  line-height: 1.75;
}

a {
  color: darken($primary, 10%);
  &:hover {
    color: darken($primary, 20%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-weight: 700;*/
  @include heading-font;
}

.page-section {
  padding: 75px 0;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 20px;
    font-weight: 200;
    line-height: 1.5;
    /*font-style: italic;*/
    margin-bottom: 75px;
    text-transform: none;
    /*@include serif-font;*/
  }
  h4.section-subheading {
    font-size: 16px;
    font-weight: 200;
    /*font-style: italic;*/
    text-align: left;
    padding-top: 15px;
    margin-bottom: 75px;
    text-transform: none;
    /*@include serif-font;*/
  }
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}
